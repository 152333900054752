import React from 'react';

function SubscribeHome() {
  return (
    <svg viewBox="0 0 405 368" fill="none">
<path d="M332.333 118.958C330.428 119.481 328.638 120.306 327.309 121.877C326.553 122.764 325.956 123.77 325.512 124.86C325.009 126.029 324.69 127.242 324.915 128.554C324.965 128.9 324.911 129.261 324.927 129.613C324.969 131.127 325.491 132.537 325.914 133.997C325.974 134.165 326.068 134.328 326.197 134.486C326.559 135.035 326.892 135.623 327.319 136.127C329.025 138.144 331.16 139.462 333.839 139.64C334.977 139.688 336.101 139.632 337.219 139.541C338.684 139.399 340.079 139.021 341.519 138.706C344.255 138.063 346.589 136.842 348.357 134.641C349.819 132.767 350.613 130.672 350.193 128.257C350.058 127.57 349.958 126.877 349.892 126.179C349.736 124.858 349.314 123.646 348.782 122.414C348.315 121.385 347.625 120.53 346.708 119.814C346.312 119.518 345.915 119.222 345.519 118.925C344.528 118.185 343.403 117.746 342.195 117.708C340.314 117.662 338.488 117.75 336.652 118.015C335.212 118.329 333.763 118.574 332.333 118.958ZM339.66 125.608C340.363 125.577 341.041 125.62 341.724 125.698C342.407 125.776 342.982 126.082 343.473 126.542C344.365 127.332 344.58 128.574 343.914 129.59C343.484 130.289 342.886 130.799 342.075 131.058C341.739 131.178 341.407 131.332 341.096 131.377C338.933 131.831 336.771 132.285 334.558 132.145C333.736 132.087 332.964 131.881 332.32 131.337C331.551 130.67 331.352 130.027 331.637 129.067C331.824 128.403 332.175 127.893 332.714 127.461C333.609 126.801 334.568 126.344 335.642 126.189C336.295 126.059 336.958 125.998 337.616 125.903C338.309 125.803 338.992 125.634 339.66 125.608Z" fill="#EADCC3"/>
<path d="M333.024 149.715C331.806 149.576 330.577 149.577 329.344 149.613C328.356 149.67 327.757 150.111 327.401 150.994C327.051 151.807 327.015 152.647 327.013 153.49C326.959 154.961 327.76 155.872 329.25 156.104C329.492 156.159 329.773 156.148 330.05 156.206C330.497 156.315 330.947 156.388 331.392 156.531C332.246 156.814 332.687 157.414 332.892 158.309C333.281 160.345 332.494 162.175 330.716 163.253C330.53 163.378 330.312 163.465 330.126 163.589C329.311 164.082 328.461 164.572 327.644 165.1C326.751 165.692 326.29 166.566 326.233 167.65C326.167 168.838 326.032 170.021 325.931 171.207C325.911 171.451 325.89 171.695 325.907 171.907C325.993 172.547 326.35 172.893 326.984 172.877C327.37 172.875 327.766 172.768 328.095 172.62C328.97 172.238 329.779 171.815 330.619 171.429C331.317 171.067 331.978 170.737 332.676 170.374C333.63 169.893 334.534 170.005 335.393 170.64C335.727 170.844 336.023 171.08 336.319 171.316C337.039 171.939 337.887 172.292 338.776 172.578C339.837 172.914 340.921 172.971 342.005 173.028C343.609 173.164 345.144 172.873 346.633 172.296C347.835 171.801 348.714 170.963 349.236 169.777C349.676 168.726 349.977 167.663 350.072 166.547C350.418 162.466 350.764 158.386 351.111 154.306C351.146 153.887 351.15 153.431 351.113 153.041C351.004 151.838 350.331 151.078 349.101 151.079C348.787 151.052 348.474 151.026 348.16 150.999C345.509 150.774 342.859 150.549 340.208 150.324C337.802 150.12 335.43 149.919 333.024 149.715ZM344.108 164.39C343.696 165.514 342.771 166.068 341.606 166.144C340.861 166.222 340.191 165.849 339.677 165.278C339.004 164.518 338.589 163.605 338.643 162.556C338.674 161.786 338.669 161.013 338.734 160.245C338.981 158.159 339.761 157.663 341.552 157.674C341.621 157.68 341.691 157.686 341.761 157.692C343.647 157.817 344.457 158.623 344.614 160.498C344.639 161.027 344.344 163.672 344.108 164.39Z" fill="#EADCC3"/>
<path d="M328.642 213.355C330.748 214.117 332.855 214.879 334.961 215.641C335.554 215.855 336.137 215.991 336.78 215.963C337.423 215.935 337.895 215.659 338.142 215.079C338.556 214.037 338.936 212.984 339.284 211.919C339.537 211.117 339.135 210.376 338.381 209.992C338.129 209.864 337.866 209.768 337.636 209.685C335.003 208.733 332.402 207.793 329.757 206.874C329.395 206.743 329.045 206.579 328.647 206.547C326.993 206.283 325.584 205.55 324.2 204.641C322.97 203.824 322.579 202.64 322.714 201.238C322.87 199.88 323.583 198.835 324.805 198.235C325.815 197.707 326.814 198.031 327.42 199.031C327.579 199.312 327.726 199.626 327.906 199.951C328.287 200.647 328.85 201.149 329.664 201.369C330.28 201.517 330.836 201.42 331.194 200.843C331.54 200.298 331.864 199.708 332.079 199.116C332.439 198.018 332.733 196.896 333.027 195.774C333.053 195.597 333.059 195.376 333.085 195.2C333.15 194.404 332.787 193.863 332.143 193.482C331.904 193.321 331.629 193.259 331.41 193.142C330.117 192.6 328.741 192.289 327.362 192.088C326.492 191.922 325.631 191.834 324.745 191.812C323.041 191.791 321.694 192.532 320.577 193.766C318.996 195.464 317.992 197.52 317.152 199.636C316.881 200.282 316.808 201 316.648 201.649C315.787 205.06 317.102 208.215 320.146 209.986C321.128 210.564 322.179 211.056 323.31 211.427C325.099 212.037 326.864 212.713 328.642 213.355Z" fill="#EADCC3"/>
<path d="M320.669 243.781C320.973 243.953 321.295 244.095 321.6 244.267C322.152 244.499 322.66 244.384 323.088 243.983C323.304 243.743 323.459 243.469 323.614 243.195C324.058 242.481 324.471 241.75 324.872 240.971C325.13 240.514 325.436 240.044 325.634 239.552C325.956 238.769 325.797 238.197 325.083 237.753C323.834 237.046 322.684 236.235 321.47 235.468C320.682 234.942 319.708 234.673 318.886 234.208C316.98 233.17 315.091 232.101 313.219 231.003C312.457 230.572 311.86 229.993 311.414 229.218C310.639 227.815 311.215 226.371 312.714 225.852C313.768 225.483 314.805 225.426 315.849 225.855C316.571 226.143 317.293 226.431 317.98 226.779C319.49 227.593 320.935 228.451 322.428 229.295C322.855 229.536 323.329 229.764 323.704 230.097C324.68 230.93 325.811 231.49 326.955 232.097C327.29 232.286 327.643 232.445 328.012 232.574C328.986 232.843 329.528 232.667 330.075 231.77C330.795 230.569 331.501 229.32 332.177 228.054C332.664 227.122 332.452 226.359 331.607 225.72C331.289 225.5 330.937 225.341 330.602 225.152C327.372 223.325 324.143 221.499 320.883 219.655C320.047 219.143 319.129 218.704 318.19 218.374C316.007 217.541 313.836 217.399 311.63 218.242C309.745 218.945 308.051 219.877 306.987 221.688C306.867 221.901 306.702 222.05 306.551 222.246C305.17 224.118 304.294 226.236 304.3 228.572C304.322 232.444 306.019 235.415 309.366 237.388C311.255 238.457 313.144 239.525 315.002 240.576C316.891 241.644 318.78 242.713 320.669 243.781Z" fill="#EADCC3"/>
<path d="M289.233 247.36C287.924 249.047 287.571 251.053 287.94 253.054C288.521 256.078 290.547 257.972 293.393 258.954C294.142 259.195 294.841 259.218 295.579 258.913C296.247 258.641 296.914 258.368 297.576 258.047C299.014 257.316 300.371 256.52 301.793 255.866C302.428 255.522 303.177 255.316 303.887 255.213C304.697 255.099 305.21 255.466 305.493 256.232C305.896 257.183 305.72 258.074 305.189 258.904C304.658 259.734 304.022 260.526 303.136 261.072C302.595 261.355 302.086 261.71 301.577 262.064C301.205 262.304 300.811 262.572 300.472 262.883C299.93 263.39 299.896 263.991 300.333 264.566C301.632 266.191 302.959 266.718 304.879 265.835C307.772 264.521 309.88 262.398 311.561 259.798C312.049 259.023 312.503 258.176 312.86 257.34C313.567 255.62 313.553 253.903 312.79 252.169C312.235 250.961 311.438 250.052 310.378 249.247C308.8 248.026 307.024 247.723 305.159 248.426C303.415 249.09 301.692 249.727 300.078 250.676C299.258 251.14 298.426 251.505 297.47 251.635C296.07 251.813 295.279 250.954 295.472 249.539C295.615 248.577 296.13 247.823 296.945 247.31C298.018 246.645 299.172 246.045 300.299 245.424C300.72 245.178 301.141 244.933 301.502 244.595C301.973 244.12 301.974 243.671 301.592 243.141C301.03 242.331 300.293 241.74 299.441 241.236C298.408 240.677 297.485 240.653 296.445 241.166C294.017 242.403 291.817 243.915 290.24 246.104C289.868 246.568 289.561 246.951 289.233 247.36Z" fill="#EADCC3"/>
<path d="M289.257 282.846C290.19 282 291.097 281.176 292.005 280.353C292.782 279.648 293.56 278.942 294.337 278.236C295.296 277.366 295.474 276.26 295.061 275.075C294.974 274.823 294.837 274.569 294.725 274.34C294.053 272.871 292.902 272.592 291.681 273.747C291.108 274.313 290.395 274.725 289.704 275.162C288.887 275.668 287.997 275.624 287.232 275.042C286.611 274.566 285.99 274.09 285.473 273.52C282.839 270.617 280.181 267.688 277.523 264.759C277.241 264.448 276.958 264.137 276.65 263.849C275.982 263.321 275.462 263.321 274.746 263.782C273.648 264.447 272.691 265.268 271.923 266.296C271.258 267.23 271.156 267.795 271.805 268.719C272.059 269.103 272.365 269.439 272.694 269.802C275.329 272.705 277.986 275.634 280.644 278.563C281.162 279.133 281.627 279.751 281.914 280.483C282.203 281.166 282.169 281.858 281.695 282.43C281.219 283.051 280.696 283.62 280.196 284.215C279.987 284.453 279.73 284.639 279.57 284.879C279.093 285.5 279.116 286.046 279.582 286.663C280.257 287.563 281.231 287.908 282.288 288.083C283.223 288.228 283.915 287.741 284.54 287.127C286.121 285.692 287.676 284.281 289.257 282.846Z" fill="#EADCC3"/>
<path d="M241.485 286.459C239.636 287.526 238.552 289.25 238.137 291.242C237.532 294.261 238.692 296.78 240.956 298.765C241.558 299.271 242.197 299.557 242.996 299.553C243.716 299.553 244.437 299.553 245.171 299.506C246.779 299.373 248.336 299.149 249.9 299.08C250.617 299.002 251.389 299.094 252.086 299.267C252.878 299.468 253.215 300.002 253.187 300.817C253.201 301.851 252.701 302.609 251.896 303.177C251.09 303.744 250.203 304.237 249.176 304.408C248.568 304.465 247.963 304.601 247.358 304.737C246.922 304.819 246.457 304.918 246.025 305.077C245.332 305.343 245.073 305.887 245.26 306.583C245.848 308.579 246.879 309.568 248.99 309.476C252.165 309.354 254.918 308.184 257.458 306.413C258.202 305.879 258.943 305.267 259.589 304.628C260.894 303.302 261.53 301.708 261.479 299.814C261.421 298.485 261.026 297.343 260.35 296.197C259.35 294.47 257.821 293.518 255.828 293.464C253.962 293.42 252.126 293.359 250.274 293.628C249.339 293.747 248.431 293.771 247.496 293.529C246.133 293.166 245.725 292.071 246.439 290.833C246.935 289.997 247.697 289.494 248.645 289.327C249.889 289.117 251.184 288.997 252.463 288.848C252.945 288.78 253.428 288.712 253.89 288.535C254.506 288.273 254.676 287.858 254.523 287.223C254.308 286.261 253.849 285.435 253.251 284.646C252.506 283.738 251.66 283.368 250.504 283.45C247.788 283.677 245.181 284.245 242.893 285.677C242.373 285.966 241.944 286.204 241.485 286.459Z" fill="#EADCC3"/>
<path d="M224.131 320.34C224.495 320.215 224.86 320.09 225.224 319.966C228.613 318.843 230.952 316.489 232.666 313.498C233.822 311.364 234.18 309.059 233.84 306.66C233.666 305.61 233.359 304.605 232.997 303.656C231.639 299.903 229.151 297.388 225.236 296.324C224.87 296.227 224.504 296.13 224.138 296.034C222.419 295.66 220.703 295.618 218.934 295.853C217.32 296.11 215.819 296.697 214.319 297.285C213.568 297.578 212.886 298.071 212.237 298.552C211.697 298.921 211.381 299.511 211.563 300.151C211.826 301.134 212.154 302.094 212.505 303.01C212.719 303.529 213.108 303.692 213.649 303.544C214.157 303.407 214.609 303.215 215.062 303.023C215.503 302.798 215.921 302.507 216.406 302.304C216.825 302.124 217.256 301.976 217.709 301.895C219.4 301.539 220.974 301.814 222.507 302.621C224.619 303.711 225.839 305.439 226.2 307.683C226.91 312.027 223.805 315.494 220.112 315.944C219.57 315.982 219.039 316.052 218.531 316.189C217.614 316.429 217.32 316.974 217.548 317.857C217.764 318.597 218.013 319.326 218.296 320.043C218.578 320.76 219.112 321.132 219.888 321.237C220.652 321.308 221.416 321.269 222.177 321.008C222.84 320.782 223.469 320.566 224.131 320.34Z" fill="#EADCC3"/>
<path d="M204.202 306.85C204.056 305.633 203.772 304.437 203.453 303.246C203.17 302.297 202.603 301.815 201.661 301.672C200.789 301.519 199.964 301.677 199.143 301.87C197.7 302.156 196.998 303.146 197.116 304.65C197.117 304.897 197.193 305.169 197.199 305.451C197.197 305.911 197.23 306.366 197.193 306.832C197.114 307.728 196.632 308.296 195.808 308.701C193.917 309.549 191.955 309.205 190.496 307.723C190.332 307.571 190.197 307.379 190.033 307.226C189.366 306.547 188.693 305.833 187.991 305.159C187.21 304.427 186.253 304.18 185.185 304.374C184.013 304.584 182.831 304.725 181.654 304.9C181.411 304.936 181.169 304.972 180.967 305.038C180.364 305.269 180.109 305.696 180.271 306.309C180.363 306.685 180.558 307.045 180.777 307.331C181.351 308.095 181.949 308.784 182.518 309.513C183.031 310.109 183.505 310.676 184.018 311.271C184.706 312.089 184.806 312.994 184.386 313.977C184.264 314.349 184.103 314.691 183.942 315.034C183.501 315.878 183.353 316.785 183.28 317.716C183.197 318.825 183.392 319.893 183.586 320.961C183.823 322.553 184.46 323.98 185.364 325.296C186.123 326.351 187.141 327.013 188.415 327.248C189.54 327.434 190.644 327.482 191.752 327.317C195.802 326.714 199.852 326.111 203.903 325.507C204.318 325.445 204.763 325.344 205.133 325.218C206.279 324.835 206.863 324.005 206.579 322.808C206.533 322.497 206.486 322.185 206.44 321.874C206.048 319.243 205.656 316.612 205.264 313.981C204.908 311.592 204.558 309.238 204.202 306.85ZM192.476 321.017C191.287 320.875 190.535 320.103 190.192 318.986C189.945 318.28 190.154 317.541 190.591 316.91C191.175 316.08 191.968 315.466 193.001 315.277C193.758 315.129 194.509 314.946 195.271 314.832C197.358 314.592 198.02 315.237 198.422 316.982C198.432 317.051 198.442 317.12 198.453 317.189C198.766 319.054 198.168 320.027 196.379 320.612C195.87 320.759 193.229 321.081 192.476 321.017Z" fill="#EADCC3"/>
<path d="M172.967 313.272C172.768 311.307 172.253 309.404 170.925 307.832C170.176 306.939 169.284 306.182 168.283 305.563C167.214 304.873 166.071 304.356 164.741 304.359C164.391 304.351 164.044 304.237 163.695 304.194C162.194 303.983 160.717 304.263 159.207 304.438C159.032 304.468 158.855 304.534 158.678 304.635C158.076 304.901 157.441 305.131 156.872 305.467C154.6 306.814 152.944 308.7 152.322 311.311C152.086 312.426 151.954 313.543 151.858 314.661C151.753 316.129 151.894 317.568 151.964 319.04C152.142 321.845 152.958 324.35 154.833 326.46C156.437 328.214 158.371 329.345 160.822 329.333C161.522 329.315 162.222 329.331 162.921 329.383C164.25 329.449 165.515 329.234 166.818 328.915C167.911 328.626 168.869 328.089 169.727 327.304C170.086 326.962 170.444 326.621 170.802 326.279C171.698 325.425 172.317 324.39 172.556 323.205C172.915 321.358 173.133 319.543 173.177 317.688C173.107 316.216 173.107 314.746 172.967 313.272ZM165.189 319.389C165.103 320.087 164.947 320.748 164.756 321.409C164.565 322.07 164.168 322.586 163.633 322.993C162.705 323.741 161.445 323.746 160.554 322.92C159.937 322.38 159.533 321.705 159.412 320.862C159.351 320.511 159.254 320.158 159.262 319.843C159.174 317.636 159.086 315.428 159.593 313.269C159.787 312.469 160.119 311.741 160.762 311.196C161.548 310.55 162.215 310.461 163.115 310.902C163.738 311.197 164.183 311.628 164.519 312.231C165.02 313.223 165.311 314.245 165.286 315.33C165.305 315.996 165.254 316.66 165.238 317.324C165.222 318.024 165.275 318.726 165.189 319.389Z" fill="#EADCC3"/>
<path d="M145.202 306.055C145.275 305.677 145.382 305.306 145.42 304.921C145.502 303.939 145.141 303.406 144.158 303.145C144.061 303.091 143.958 303.071 143.821 303.045C139.147 302.145 134.438 301.239 129.723 300.368C129.242 300.275 128.734 300.32 128.226 300.365C127.794 300.389 127.425 300.638 127.146 300.977C126.616 301.695 126.176 302.501 125.942 303.347C125.701 304.227 126.035 304.898 126.909 305.173C127.845 305.495 128.822 305.79 129.778 306.01C131.627 306.401 133.496 306.69 135.38 307.088C136.714 307.38 137.357 308.11 137.484 309.489C137.55 310.072 137.465 310.697 137.346 311.315C136.607 315.343 135.799 319.358 135.025 323.379C134.946 323.792 134.866 324.204 134.821 324.623C134.733 325.64 135.136 326.323 136.092 326.543C137.212 326.83 138.381 327.055 139.528 327.204C140.429 327.342 141.057 326.857 141.338 325.948C141.48 325.584 141.518 325.199 141.591 324.821C142.193 321.693 142.801 318.531 143.41 315.369C144.005 312.276 144.6 309.183 145.202 306.055Z" fill="#EADCC3"/>
<path d="M119.479 300.492C119.615 300.132 119.783 299.784 119.886 299.411C120.133 298.457 119.868 297.871 118.942 297.448C118.856 297.378 118.758 297.341 118.627 297.292C114.173 295.614 109.685 293.925 105.185 292.268C104.727 292.095 104.219 292.053 103.711 292.012C103.281 291.962 102.875 292.146 102.543 292.432C101.899 293.05 101.329 293.77 100.955 294.564C100.569 295.391 100.785 296.108 101.599 296.527C102.467 297.003 103.38 297.459 104.285 297.837C106.041 298.536 107.835 299.137 109.624 299.848C110.889 300.362 111.4 301.19 111.291 302.57C111.258 303.156 111.069 303.758 110.847 304.347C109.436 308.192 107.96 312.012 106.517 315.844C106.369 316.237 106.221 316.63 106.106 317.036C105.847 318.023 106.128 318.765 107.033 319.143C108.089 319.615 109.203 320.035 110.309 320.376C111.173 320.664 111.874 320.292 112.305 319.445C112.506 319.109 112.609 318.737 112.745 318.376C113.867 315.396 115.002 312.382 116.136 309.369C117.246 306.421 118.357 303.473 119.479 300.492Z" fill="#EADCC3"/>
<path d="M312.024 119.666C329.802 187.727 281.297 269.087 207.835 288.275C134.373 307.463 57.5997 273.884 37.9285 198.573C10.4445 126.928 50.019 61.6178 143.456 41.803C216.919 22.6146 281.135 38.3459 312.024 119.666Z" fill="#EADCC3"/>
</svg>
  )
}

export default SubscribeHome;
