import React, { useContext } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import { StateContext } from  '@/providers/state';

import Layout from '@/components/layout';
import SEO from '@/components/seo';
import Waves from '@/components/waves';
import SubscribeHome from '@/components/subscribe-home';
import Sheep from '@/assets/img/sheep.svg';

function IndexPage() {
  const { toggleHighlightSubscribe, toggleMenuExpanded } = useContext(StateContext) ?? {};

  function openSubscribe() {
    toggleHighlightSubscribe(true);
    toggleMenuExpanded(true);
  }

  return (
    <Layout home={true}>
      <SEO title="Home" />

      <section
        className="p-6 bg-clay text-right font-title text-wheat tracking-widest leading-loose relative z-10"
        style={{ minHeight: '60vh' }}>
        <div className="text-charcoal relative" style={{ fontSize: '2.5vw', left: '-42vw' }}>Julian, CA</div>
        <h1 className="" style={{ fontSize: '7vw' }}>Prickly Bear</h1>
        <div className="relative" style={{ fontSize: '4vw', left: '-0.5rem' }}>Farmstead</div>

        <div className="bubbles flex flex-wrap relative z-20 items-center justify-around w-full px-12">
          <Link
            className="relative flex items-center justify-center bubble"
            to="/posts">
            <div
              className="absolute text-left w-2/3 tracking-normal"
              >See What We've Been Working On</div>
            <svg viewBox="0 0 411 199" fill="none">
              <path d="M411 120.256C411 182.071 310.358 196.096 197.812 196.096C85.2663 196.096 4.01331 220.701 4.01331 126.659C-8.5648 59.6411 -3.98424 -6.8941 208.39 0.575239C368.281 0.575239 397.163 44.2564 411 120.256Z" fill="#484948"/>
            </svg>
          </Link>

          <div className="" style={{ fontSize: '2.5rem' }}>
            Or
          </div>

          <Link
            className="relative flex items-center justify-center bubble"
            to="/">
            <div
              onClick={openSubscribe}
              className="absolute text-right w-1/2 text-charcoal tracking-normal"
              style={{ right: '38%', top: '30%' }}>Hear about events and such</div>
            <SubscribeHome />
          </Link>
        </div>

        <Waves className="absolute left-0 fill-clay" style={{ top: '100%'}} />

        <StaticImage
          className="absolute left-0 opacity-50"
          style={{ height: '75vh', mixBlendMode: 'multiply', position: 'absolute', top: '80%' }}
          src="../assets/img/dots.png"
          alt="Dots"
          loading="eager"
          layout="fixed"
          placeholder="tracedSVG" />
      </section>

      <section className="font-body z-10 p-6 pt-24 relative" style={{ bottom: '-5vh' }}>
        <div className="container mx-auto flex px-5 md:flex-row flex-col items-center py-24">
          <div className="lg:max-w-lg lg:w-full md:w-2/5 w-5/6 mb-10 md:mb-0">
            <StaticImage
              src="../assets/img/pricklybear.png"
              alt="Logo Alternate"
              layout="constrained"
              placeholder="tracedSVG" />
          </div>

          <div className="lg:flex-grow md:w-3/5 lg:pl-24 md:pl-16 flex flex-col md:items-end text-right items-center">
            <h1 className="text-2xl md:text-5xl mb-4 tracking-wider text-right pt-24">Who The Hell Are Ye?</h1>

            <p className="mb-8 leading-relaxed text-justify text-lg">
              Andrew and Bernadette Haupt. Both originally from Southern California, we decided to move back after living in Oregon for a few years to find a piece of land and small community that did not feel at all like Southern California. Julian fits the bill pretty well, and we were lucky enough to stumble upon 5 semi-affordable acres and move out here in April 2020. Thus was born Prickly Bear Farmstead.
            </p>
          </div>
        </div>
      </section>

      <section className="font-body z-10 p-4 relative">
        <div className="container mx-auto flex px-5 md:flex-row flex-col items-center pb-24">
          <div className="lg:flex-grow md:w-1/2 lg:pr-12 md:pr-6 flex flex-col md:items-start text-left items-center">
            <h1 className="text-2xl md:text-5xl mb-4 tracking-wider text-right pt-12">What's the idea here?</h1>

            <p className="mb-4 leading-relaxed text-justify text-lg">
              Conventional agriculture has some serious problems. California has some serious water issues. We're interested in experimenting with coupling drought tolerant perennial food sources with creative storage and use of our available water. So far we've added a 5k gallon <span className="font-normal">rain tank</span> to catch water off the roof, a whole house <span className="font-normal">graywater system</span> feeding fruit trees, hand dug <span className="font-normal">swales and other earthworks</span>, and <span className="font-normal">compost toilets</span>. We've also planted a bunch of <span className="font-normal">native hazelnut trees and bush chinquapin</span>, and are actively searching for <span className="font-normal">varieties of annual crops</span> that thrive on little water.
            </p>
          </div>

          <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0 relative" style={{ right: '-7vw' }}>
            <div className="opacity-50">
              <StaticImage
                style={{ mixBlendMode: 'darken' }}
                src="../assets/img/dots2.png"
                alt="Dots"
                layout="fixed"
                placeholder="tracedSVG" />

              <StaticImage
                className="absolute right-0 bottom-0"
                style={{ mixBlendMode: 'multiply', position: 'absolute' }}
                src="../assets/img/cactus.png"
                alt="Cactus"
                layout="constrained"
                width={300}
                placeholder="tracedSVG" />
              </div>
          </div>
        </div>
      </section>

      <section className="font-body z-10 p-6 relative">
        <div
          className="w-full h-48"
          style={{ backgroundImage: `url(${Sheep})`, backgroundSize: '85px', width: '105vw', left: '-3vw' }}></div>

        <div className="container mx-auto items-center pb-24 flex flex-col items-center">
          <h1 className="text-2xl md:text-5xl mb-4 tracking-wider text-center pt-12">You've Got Classes and Such?</h1>

          <p className="mb-8 leading-relaxed text-justify text-lg pt-4 max-w-5xl">
            We are by no means experts to any of this homesteading stuff, but we have a serious drive to learn. So we've started holding classes and workshops on a regular basis to build community and propagate the type of practical old school knowledge that many people (us included) lack these days. Ya know, the things your great grandparents knew how to do. The list of classes we want to have are basically endless, so if you or someone you know is an expert in something and wants to spread the knowledge, please <Link to="/contact" className="text-clay font-normal">let us know</Link>.
          </p>
        </div>
      </section>
    </Layout>
  )
}

export default IndexPage
