import { styledComponent } from '@/model/styled-component';
import React from 'react';

function Waves({ className, style }: typeof styledComponent) {
  return (
    <svg className={`${className} w-full`} style={style} viewBox="0 0 1373 391">
      <path d="M 0 0 C 427.2 0 284.8 154 712 154 L 712 154 L 712 0 L 0 0 Z"></path>
      <path d="M 711 154 C 1108.2 154 975.8 92 1373 92 L 1373 92 L 1373 0 L 711 0 Z"></path>
    </svg>
  )
}

Waves.defaultProps = styledComponent;

export default Waves;
